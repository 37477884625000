@tailwind base;
@tailwind components;
@tailwind utilities;

.body {
  background-color: #0f0f0f;
}

.card:hover {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}

.card-back {
  transform: rotateY(180deg);
}
